import Vue from 'vue'
import Router from 'vue-router'
import AuthRequired from '@/utils/AuthRequired'

Vue.use(Router)

const routes = [
    {
        path: '/order',
        component: () => import(/* webpackChunkName: "backend" */ '@/website/backend'),
        redirect: '/order',
        beforeEnter: AuthRequired,
        children: [
            {
                path: '/add-order', component: () => import(/* webpackChunkName: "add-order" */ '@/website/backend/add_order'),
                meta: { title: 'เพิ่มออเดอร์ใหม่' }
            },
            {
                path: '/import-order-by-excel', component: () => import(/* webpackChunkName: "add-order" */ '@/website/backend/add_order/add_order_excel'),
                meta: { title: 'นำเข้าออเดอร์จาก Excel' }
            },
            {
                path: '/add-order-success', component: () => import(/* webpackChunkName: "add-order-success" */ '@/website/backend/add_order/add_order_success'),
                meta: { title: 'เพิ่มออเดอร์สำเร็จ' }
            },
            {
                path: '/order', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order'),
                meta: { title: 'ออเดอร์ของฉัน' }
            },
            {
                path: '/product-pack-list', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/productPackList'),
                meta: { title: 'จัดส่งพัสดุ' }
            },
            {
                path: '/order-detail', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/order_detail.vue'),
                meta: { title: 'OrderDetail' }
            },
            {
                path: '/mhao-order-item', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/mhao_order_item.vue'),
                meta: { title: 'ตรวจสอบสินค้าเหมาๆ' }
            },
            {
                path: '/deliveryprice', component: () => import('@/website/backend/deliveryprice'),
                meta: { title: 'ค่าขนส่ง' }
            },
            {
                path: '/mhao-chaina-order', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/mhao_chaina_order.vue'),
                meta: { title: 'รายการสั่งซื้อสินค้า' }
            },
            {
                path: '/mhao-chaina-check-order', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/mhao_chaina_check_order.vue'),
                meta: { title: 'รายการสั่งซื้อสินค้า' }
            },
            {
                path: '/stock-history/:product_name', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_history'),
                meta: { title: 'Stock History' }
            },
            {
                path: '/stock-history', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_history/stock_history.vue'),
                meta: { title: 'Stock History' }
            },
            {
                path: '/my-product', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_new'),
                meta: { title: 'สินค้าของฉัน' }
            },
            {
                path: '/my-stock', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_new/allstock'),
                meta: { title: 'สต๊อกสินค้า' }
            },
            {
                path: '/update/excel', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_new/addstock_excel'),
                meta: { title: 'เพิ่มสินค้า excel' }
            },
            {
                path: '/stock-info', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_new/stock-info'),
                meta: { title: 'Stock' }
            },
            {
                path: '/stock-report', component: () => import(/* webpackChunkName: "stock-history" */ '@/website/backend/stock_new/allstock-info'),
                meta: { title: 'ภาพรวมการเดินสต๊อก' }
            },
            {
                path: '/my-product/update', component: () => import(/* webpackChunkName: "add-stock" */ '@/website/backend/stock_new/addstock'),
                meta: { title: 'อัพเดทสินค้า' }
            },
            {
                path: '/transfer-list/update', component: () => import(/* webpackChunkName: "add-stock" */ '@/website/backend/stock_new/import'),
                meta: { title: '' }
            },
            {
                path: '/transfer-list', component: () => import(/* webpackChunkName: "add-stock" */ '@/website/backend/stock_new/import_master'),
                meta: { title: 'นำเข้า/นำออก/โอนย้ายสินค้า' }
            },
            {
                path: '/report', component: () => import(/* webpackChunkName: "report" */ '@/website/backend/report'),
                meta: { title: 'รายงาน COD' }
            },
            {
                path: '/report-sales', component: () => import(/* webpackChunkName: "report-detail-order" */ '@/website/backend/report/report_sales'),
                meta: { title: 'รายงานยอดขายสินค้า' }
            },
            {
                path: '/report-space', component: () => import(/* webpackChunkName: "report-detail-order" */ '@/website/backend/report/report_space'),
                meta: { title: 'รายงานการใช้พื้นที่' }
            },
            {
                path: '/report-sales1', component: () => import(/* webpackChunkName: "report-detail-order" */ '@/website/backend/report/report_sales_card1'),
                meta: { title: 'รายงานยอดขายสินค้าทั้งหมด' }
            },
            {
                path: '/report-sales2', component: () => import(/* webpackChunkName: "report-detail-order" */ '@/website/backend/report/report_sales_card2'),
                meta: { title: 'Report' }
            },
            // {
            //     path: '/manageuser', component: () => import(/* webpackChunkName: "staff_manage" */ '@/website/backend/manageUser/'),
            //     meta: { title: 'จัดการผู้ใช้' }
            // },
            {
                path: '/manageuser', component: () => import(/* webpackChunkName: "staff_manage" */ '@/website/backend/manageUser/index_new'),
                meta: { title: 'จัดการผู้ใช้' }
            },
            {
                path: '/wallet', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/'),
                meta: { title: 'wallet ของฉัน' }
            },
            {
                path: '/notify_transfer', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/notify_transfer'),
                meta: { title: 'แจ้งชำระเงิน' }
            },
            {
                path: '/notify_withdraw', component: () => import(/* webpackChunkName: "withdraw" */ '@/website/backend/withdraw'),
                meta: { title: 'แจ้งถอนเงิน' }
            },
            {
                path: '/view_detail_invoice', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/view_detail_invoice'),
                meta: { title: 'view_detail_invoice' }
            },
            {
                path: '/invoice-group', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/invoiceGroup'),
                meta: { title: 'รายการใบแจ้งหนี้ที่จัดกลุ่ม' }
            },

            {
                path: '/shop-setting', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/setting'),
                meta: { title: 'ตั้งค่าข้อมูลร้าน' }
            },
            {
                path: '/dashboard', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/dashboard'),
                meta: { title: 'Dashboard' }
            },
            {
                path: '/dashboard_admin_sale', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/dashboard/dashboard_admin_sale'),
                meta: { title: 'ยอดขายตามแอดมิน' }
            },
            {
                path: '/dashboard_best_sale_product', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/dashboard/dashboard_best_sale_product'),
                meta: { title: 'สินค้าขายดี' }
            },
            {
                path: '/ts', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/ts/'),
                meta: { title: 'Ts' }
            },
            {
                path: '/promotion', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/promotion'),
                meta: { title: 'โปรโมชั่นสินค้า' }
            },
            {
                path: '/promotion/update', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/promotion/add_promotion'),
                meta: { title: 'เพิ่มโปรโมชั่น' }
            },
            {
                path: '/warehouse', component: () => import(/* webpackChunkName: "manage-warehouse" */ '@/website/backend/warehouse'),
                meta: { title: 'จัดการคลังสินค้า' }
            },
            {
                path: '/connect', component: () => import(/* webpackChunkName: "manage-warehouse" */ '@/website/backend/connect'),
                meta: { title: 'เชื่อมต่อ' }
            },
            {
                path: '/category-setting', component: () => import(/* webpackChunkName: "manage-catagories" */ '@/website/backend/manage_category'),
                meta: { title: 'จัดการหมวดหมู่' }
            },
            {
                path: '/cod-wallet', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/cod_wallet/'),
                meta: { title: 'cod wallet ของฉัน' }
            },
            {
                path: '/view-detail-cod-wallet', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/cod_wallet/view_detail_cod_wallet.vue'),
                meta: { title: 'รายละเอียด cod wallet' }
            },
            {
                path: '/packing', component: () => import(/* webpackChunkName: "packing" */ '@/website/backend/china_packing'),
                meta: { title: 'packing' }
            },
            {
                path: '/history-tax-invoice', component: () => import(/* webpackChunkName: "packing" */ '@/website/backend/invoice'),
                meta: { title: 'ประวัติจัดเก็บ invoice' }
            },
            {
                path: '/manage-customer', component: () => import(/* webpackChunkName: "packing" */ '@/website/backend/manage_customer/manage_customer.vue'),
                meta: { title: 'ลูกค้า' }
            },
            {
                path: '/chat', component: () => import(/* webpackChunkName: "packing" */ '@/website/backend/manage_customer/chat.vue'),
                meta: { title: 'ลูกค้า' }
            },
            {
                path: '/shop-agent', component: () => import(/* webpackChunkName: "packing" */ '@/website/backend/shop_agent'),
                meta: { title: 'ตัวแทน' }
            },
            {
                path: '/consider_transfer_master', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/agent/consider_transfer_master'),
                meta: { title: 'รายการตัวแทนแจ้งโอนเงิน' }
            },
            {
                path: '/wallet_master', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/agent/wallet_master'),
                meta: { title: 'รายการ balance' }
            },
            {
                path: '/notify_transfer_agent', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/agent/notify_transfer_agent'),
                meta: { title: 'แจ้งชำระเงิน' }
            },
            {
                path: '/wallet_agent', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/finance/agent/wallet_agent'),
                meta: { title: 'รายการ balance' }
            },{
                path: '/feedback', component: () => import(/* webpackChunkName: "finance" */ '@/website/backend/feedback'),
                meta: { title: 'ข้อเสนอแนะ' }
            },
            {
                path: '/teach', component: () => import(/* webpackChunkName: "report" */ '@/website/backend/teachuser'),
                meta: { title: 'ตั้งค่าการใช้งาน' }
            },
            {
                path: '/package', component: () => import(/* webpackChunkName: "report" */ '@/website/backend/package'),
                meta: { title: 'แพ็กเกจ' }
            },
            {
                path: '/order-and-delivery-tracking', component: () => import(/* webpackChunkName: "order" */ '@/website/backend/order/order_and_Delivery_Tracking.vue'),
                meta: { title: 'ติดตามคำสั่งซื้อและการจัดส่ง' }
            },


        ]
    },
    {
        path: '/error', component: () => import(/* webpackChunkName: "error" */ '@/website/Error'),
        meta: { title: 'Error' }
    },
    {
        path: '*', component: () => import(/* webpackChunkName: "error" */ '@/website/Login'),
        meta: { title: 'Login: PACKHAI Fulfillment เก็บ แพ็ค ส่ง' }
    },
    {
        path: '/login', component: () => import(/* webpackChunkName: "login" */ '@/website/Login'),
        meta: { title: 'Login: PACKHAI Fulfillment เก็บ แพ็ค ส่ง ' }
    },
    {
        path: '/LoginOasys', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/LoginOasys'),
        meta: { title: 'Login: Oasys ' }
    },
    {
        path: '/auth/register', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/RegisterOasys'),
        meta: { title: 'สมัครสมาชิก ' }
    },
    {
        path: '/auth/facebook/register', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/RegisterFacebookOasys'),
        meta: { title: 'สมัครสมาชิก ' }
    },
    {
        path: '/auth/confirm-sign-up', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/confirmEmail'),
        meta: { title: 'สมัครสมาชิก ' }
    },
    {
        path: '/auth/forgot-password', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/forgotPassword'),
        meta: { title: 'ลืมรหัสผ่าน ' }
    },
    {
        path: '/auth/reset-password', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/oasysRegister/resetPassword'),
        meta: { title: 'เปลี่ยนรหัสผ่าน ' }
    },
    {
        path: '/ss/login', component: () => import(/* webpackChunkName: "login" */ '@/website/Agent_Login'),
        meta: { title: 'Login: SATISFY SOLUTION ซื้อง่าย ขายได้จริง ' }
    },
    {
        path: '/print', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/order/print.vue'),
        meta: { title: 'Print' }
    },
    {
        path: '/register', component: () => import(/* webpackChunkName: "register" */ '@/website/Register'),
        meta: { title: 'สมัครสมาชิก' }
    },
    {
        path: '/ss/register', component: () => import(/* webpackChunkName: "register" */ '@/website/Agent_Register'),
        meta: { title: 'สมัครสมาชิก' }
    },
    {
        path: '/thankyou', component: () => import(/* webpackChunkName: "register" */ '@/website/backend/register/register_success'),
        meta: { title: 'Thankyou' }
    },
    {
        path: '/ss/thankyou', component: () => import(/* webpackChunkName: "register" */ '@/website/backend/register/agent_success'),
        meta: { title: 'Thankyou' }
    },
    {
        path: '/print-invoice', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/finance/printNew.vue'),
        meta: { title: 'ปริ้นใบแจ้งหนี้' }
    },
    {
        path: '/print-tax-invoice', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/order/print_tax_invoice.vue'),
        meta: { title: 'ปริ้นใบแจ้งหนี้' }
    },
    {
        path: '/print-cover-pages', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/order/print_cover_pages.vue'),
        meta: { title: 'ปริ้นใบปะหน้า' }
    },
    {
        path: '/printPackingList', component: () => import('@/website/backend/order/printPackingList'),
        meta: { title: 'พิมพ์ใบ PackingList' }
    },
    {    
        path: '/printAws', component: () => import('@/website/backend/order/printAws'),
        meta: { title: 'พิมพ์ใบ Aws' }
    },
    {
        path: '/shopee/callback', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/callback/shopee.vue'),
        meta: { title: 'callback' }
    },
    {
        path: '/lazada/callback', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/callback/lazada.vue'),
        meta: { title: 'callback' }
    },
    {
        path: '/jd/callback', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/callback/jd.vue'),
        meta: { title: 'callback' }
    },
    {
        path: '/line/callback', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/callback/line_notify.vue'),
        meta: { title: 'callback' }
    },
    {
        path: '/tiktok/callback', component: () => import(/* webpackChunkName: "login" */ '@/website/backend/callback/tiktok.vue'),
        meta: { title: 'callback' }
    },
]

const router = new Router({
    routes,
    mode: 'history',
    linkActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router
