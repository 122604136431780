import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
// Router
import router from './router/'
// CSS
import './website/css/style.scss'
// VueSweetAlert2
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueExcelXlsx from "vue-excel-xlsx";
import FBSignInButton from 'vue-facebook-signin-button'
import Sortable from 'sortablejs';


Vue.use(FBSignInButton)
Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(VueExcelXlsx);

new Vue({
  vuetify,
  render: h => h(App),
  router,
}).$mount('#app')
